<template>
  <el-dialog v-model="visible" :title="title" width="640px" class="add" :close-on-click-modal="false" :close-on-press-escape="false" @close="visible = false">
    <el-form ref="form" v-loading="loading" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别" prop="type">
        <el-select v-model="ruleForm.type" filterable placeholder="请选择" @change="onChange">
          <el-option v-for="(item,index) in types" :key="index" :label="item.type" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="type">
        <el-select :disabled="!ruleForm.type" filterable v-model="ruleForm.specs" placeholder="请选择">
          <el-option v-for="(item,index) in specs" :key="index" :label="item.specs" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单价" prop="price">
        <el-input v-model="ruleForm.price" type="number"></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="weight">
        <el-input v-model="ruleForm.weight" type="number"></el-input>
      </el-form-item>
      <el-form-item label="件数" prop="number">
        <el-input v-model="ruleForm.number" type="number"></el-input>
      </el-form-item>
      <el-form-item label="运费">
        <el-input v-model="ruleForm.freight" type="number" ></el-input>
      </el-form-item>
      <el-form-item label="入库时间" prop="stockTime">
        <el-date-picker v-model="ruleForm.stockTime" type="date" value-format="YYYY-MM-DD" placeholder="请选择"></el-date-picker>
      </el-form-item>
      <el-form-item label="购入单位" prop="buyCompany">
        <el-select v-model="ruleForm.buyCompany" filterable placeholder="请选择">
          <el-option v-for="(item,index) in companyList" :key="index" :label="item.companyName" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" >
        <el-input v-model="ruleForm.remark" ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import { ref, computed, reactive, toRefs, onMounted } from 'vue'
import * as math from 'mathjs'
import {
  getAllTypeApi,
  getSpecByTypeApi,
  saveStockApi,
  getAllCompanyApi,
  getStockDetailApi
} from './fetch'

export default ({
  props: {
    refreshList: Function,
    id: String
  },
  setup (props) {
    const form = ref(null)
    const state = reactive({
      visible: true,
      loading: false,
      ruleForm: {
        type: '',
        specs: '',
        weight: undefined,
        price: undefined,
        number: undefined,
        freight: undefined,
        buyCompany: '',
        stockTime: '',
        remark: ''
      },
      types: [],
      specs: [],
      companyList: [],
      rules: {
        type: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        specs: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        weight: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        price: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        number: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        stockTime: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        buyCompany: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      state.loading = true
      state.types = await getAllTypeApi()
      state.companyList = await getAllCompanyApi()
      if (props.id) {
        state.ruleForm = await getStockDetailApi({
          id: props.id
        })
        state.specs = await getSpecByTypeApi({ typeId: state.ruleForm.type })
      }
      state.loading = false
    })

    const title = computed(() => {
      return props.id ? '编辑入库' : '新增入库'
    })

    const money = computed(() => {
      if (state.ruleForm.price && state.ruleForm.weight) {
        return math.multiply(state.ruleForm.price, state.ruleForm.weight).toFixed(2)
      } else {
        return 0
      }
    })

    const onSubmit = async () => {
      try {
        await form.value.validate()
        console.log(typeof state.ruleForm.price)
        const data = _.cloneDeep(state.ruleForm)
        data.weight = +data.weight
        data.price = +data.price
        data.number = +data.number
        data.freight = +data.freight
        await saveStockApi({
          ...data,
          money: +money.value
        })
        state.visible = false
        props.refreshList()
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    const onChange = async (id) => {
      console.log(id)
      state.specs = await getSpecByTypeApi({ typeId: id })
    }

    return {
      ...toRefs(state),
      form,
      money,
      title,
      onSubmit,
      onChange
    }
  }
})
</script>
