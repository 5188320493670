import Request from '@/utils/request'

export function getStockApi (data) {
  return Request({
    url: '/stock/list',
    params: data
  })
}

export function getAllTypeApi () {
  return Request({
    url: '/type/all'
  })
}

export function getAllCompanyApi () {
  return Request({
    url: '/company/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/specsbytype',
    params: data
  })
}

export function saveStockApi (data) {
  return Request({
    url: '/stock/add',
    method: 'post',
    data
  })
}

export function getStockDetailApi (data) {
  return Request({
    url: '/stock/detail',
    params: data
  })
}

export function getTypesApi () {
  return Request({
    url: '/type'
  })
}

export function delStockApi (data) {
  return Request({
    url: '/stock/delete',
    params: data
  })
}
